import React from 'react'
import './i18n'
import { ChakraProvider, Container, Box } from '@chakra-ui/react'
import { render } from 'react-dom'
import { createBrowserHistory } from 'history'
import { UserProvider } from './components/user'
import App from './components/app'

render(
  <>
    <ChakraProvider>
      <Container maxW="1024px" centerContent>
        <Box h="100vh" w="100%">
          <UserProvider>
            <App />
          </UserProvider>
        </Box>
      </Container>
    </ChakraProvider>
  </>,
  document.getElementById('root'),
)
