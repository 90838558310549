import React from 'react'
import PropTypes from 'prop-types'
import { StateContext, DispatchContext } from './context'
import { reducer, initialState } from './reducer'

export { useUser } from './context'

export const UserProvider = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{props.children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node,
}
