const sv = {
  signIn: 'Logga in',
  username: 'Användarnamn',
  password: 'Lösenord',
  show: 'visa',
  hide: 'dölj',
  open: 'Öppna',
  noDoors: 'Inga dörrar...',
  isRequired: 'krävs',
  signOut: 'Logga ut',
  locations: 'Platser',
  door: 'Dörrar',
  accessCheck: 'Åtkomstkoll',
  openSuccess: 'Dörren är öppnad',
  ssnHelpText: 'Personnummer (12 siffror)',
  doCheck: 'Kontrollera åtkomst',
  accessCheckGranted: '{{ssn}} has behörighet',
  accessCheckDenied: '{{ssn}} saknar behörighet',
  toggleOpenSuccess: 'Porten är nu permanent öppen',
  toggleCloseSuccess: 'Porten är nu permanent stängd',
  confirm: {
    idleState: {
      title: 'OBS!!',
      body:
        'Du kommer nu att permanent ställa upp porten i öppet läge. Porten kommer inte stängas förrän du har avaktiverat “Permanent öppet”. Vill du ångra klicka Avbryt, för att aktivera klicka Utför.',
    },
  },

  info: 'Info',
  error: {
    title: 'Fel',
    signInFailed: 'Inloggning misslyckades',
  },
}

export default sv
