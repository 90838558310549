import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  Button,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Box,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { locations } from '../../../api'
import ReactMarkdown from 'react-markdown'

const Info = ({ location }) => {
  const [info, setInfo] = useState()
  const [scope, setScope] = useState([0, 5])
  const [translate] = useTranslation()

  const fetchData = async (id) => {
    console.log('location', location)
    const [err, data] = await locations.fetchPMCLocationInfo(id)

    if (err) {
      console.error('could not fetch pmc location info', err)
    } else {
      console.log('data', data)
      setInfo(data)
    }
  }

  useEffect(() => {
    console.log('loc', location)
    if (location) {
      fetchData(location.remoteId)
    }
  }, [location.id])

  return (
    <>
      <Flex>
        <ModalCloseButton />
        <Box flex="1">
          <Tabs>
            <TabList>
              {scope[0] > 0 && (
                <Button
                  onClick={() => {
                    if (scope[0] - 5 < 0) {
                      setScope([0, 5])
                    } else {
                      setScope([scope[0] - 5, scope[1] - 5])
                    }
                  }}
                >
                  ...
                </Button>
              )}
              {info?.slice(scope[0], scope[1]).map((data) => (
                <Tab key={data.id}>{data.name}</Tab>
              ))}
              {info?.length > scope[1] && (
                <Button
                  onClick={() => {
                    const next = scope[1] + 5
                    if (next > info?.length) {
                      setScope([scope[1], info?.length])
                    } else {
                      setScope([scope[1], next])
                    }
                  }}
                >
                  ...
                </Button>
              )}
            </TabList>

            <ModalBody>
              <TabPanels>
                {info?.slice(scope[0], scope[1]).map((data) => (
                  <TabPanel key={data.id}>
                    <ReactMarkdown>{data.description}</ReactMarkdown>
                  </TabPanel>
                ))}
              </TabPanels>
            </ModalBody>
          </Tabs>
        </Box>
      </Flex>
    </>
  )
}

Info.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    remoteId: PropTypes.string,
  }).isRequired,
}

export default Info
