const en = {
  signIn: 'Sign in',
  username: 'Username',
  password: 'Password',
  show: 'show',
  hide: 'hide',
  open: 'Open',
  noDoors: 'No doors at this location',
  isRequired: 'is required',
  signOut: 'Sign Out',
  locations: 'Locations',
  doors: 'Doors',
  accessCheck: 'Access check',
  openSuccess: 'Door is open',
  ssnHelpText: '12 digit personal SSN',
  doCheck: 'Check access',
  accessCheckGranted: '{{ssn}} has access to this location',
  accessCheckDenied: '{{ssn}} does not have access to this location',
  toggleOpenSuccess: 'The door is now permanetly open',
  toggleCloseSuccess: 'The door is now permanently closed',
  info: 'Info',
  confirm: {
    idleState: {
      title: 'Important',
      body:
        'You are about to permanently set the door state as open and it will not close until you manually do it here.',
    },
  },
  error: {
    title: 'Error',
    signInFailed: 'Sign in failed',
  },
}

export default en
