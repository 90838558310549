import { client, pmcClient } from './client'
import { serialize, snakeCase } from './utils'
import { URL } from '../config'

export const fetchLocations = async (query = {}) =>
  await client.get(`/api/v1/locations?${serialize(snakeCase(query))}`).then(
    (v) => [null, v.data],
    (err) => [err, null],
  )

export const fetchPMCLocations = async () =>
  await pmcClient.get(`/api/v2/garages`).then(
    (v) => [null, v.data],
    (err) => [err, null],
  )

export const fetchPMCLocationInfo = async (remoteId) =>
  await pmcClient.get(`/api/v2/garages/${remoteId}/info`).then(
    (v) => [null, v.data],
    (err) => [err, null],
  )

export const checkPMCAccess = async (remoteId, ssn) =>
  await pmcClient.get(`/api/v2/garages/${remoteId}/access?personal_number=${ssn}`).then(
    (v) => [null, v.data],
    (err) => [err, null],
  )

export const fetchDoors = async (query = {}) =>
  await client.get(`/api/v1/doors?${serialize(snakeCase(query))}`).then(
    (v) => [null, v.data],
    (err) => [err, null],
  )

export const openDoor = async (doorId) =>
  await client.get(`/api/v1/doors/${doorId}/open`).then(
    (v) => [null, v.data],
    (err) => [err, null],
  )

export const setDoorState = async (doorId, state) =>
  await client.get(`/api/v1/doors/${doorId}/state/${state}`).then(
    (v) => [null, v.data],
    (err) => [err, null],
  )

export const getQRCodeURL = (doorId) => {
  return `${URL}/api/v1/doors/${doorId}/qr`
}
