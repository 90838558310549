import React, { useEffect, useState } from 'react'
import { createBrowserHistory } from 'history'
import { Router, Route } from 'react-router-dom'
import { useLocalStorage } from '../hooks'
import Auth from './auth'
import Header from './header'
import { useUser } from './user'
import Locations from './locations'
import QRCode from './qrcode'
import { client } from '../api'

const history = createBrowserHistory()

const App = () => {
  const [cachedUser, cacheUser] = useLocalStorage('heimdall-user', null)
  const [user, dispatch] = useUser()

  useEffect(() => {
    if (cachedUser) {
      client.setAuth(cachedUser.jwt)
      dispatch({ type: 'set_user', payload: cachedUser })
    } else {
      client.clearAuth()
    }
  }, [])

  const handleSignInComplete = (user) => {
    client.setAuth(user.jwt)
    cacheUser(user)
    dispatch({ type: 'set_user', payload: user })
  }

  const handleSignOut = () => {
    client.clearAuth()
    cacheUser(null)
    dispatch({ type: 'remove_user' })
  }

  return (
    <>
      {!cachedUser && <Auth onSignIn={handleSignInComplete} />}
      {user && (
        <>
          <Header user={user} onSignOut={handleSignOut} />
          <Router history={history}>
            <Route exact path="/qr">
              <QRCode />
            </Route>
            <Route exact path="/">
              <Locations />
            </Route>
          </Router>
        </>
      )}
    </>
  )
}

export default App
