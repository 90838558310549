import React from 'react'

export const StateContext = React.createContext(undefined)
export const DispatchContext = React.createContext(undefined)

const useUserState = () => {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context
}

const useUserDispatch = () => {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useRootDispatch must be used within a RootProvider')
  }
  return context
}

export const useUser = () => [useUserState(), useUserDispatch()]
