import React from 'react'
import {
  HStack,
  Box,
  Heading,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const ListItem = ({ location, ...props }) => {
  const [translate] = useTranslation()

  return (
    <AccordionItem>
      <AccordionButton _expanded={{ bg: 'blue.500', color: 'white' }}>
        <Box flex="1" textAlign="left">
          <Heading size="sm">{location.name}</Heading>
        </Box>
        <Box flex="1" textAlign="left">
          {location.address}
        </Box>
        <Box>
          <AccordionIcon />
        </Box>
      </AccordionButton>
      <AccordionPanel>
        <HStack mt="1rem" spacing="1rem">
          <Button onClick={() => props.showModal('doors', location)} colorScheme="blue" variant="solid">
            {translate('doors')}
          </Button>
          <Button
            onClick={() => props.showModal('access_check', location)}
            disabled={!location.remoteId}
            colorScheme="blue"
            variant="outline"
          >
            {translate('accessCheck')}
          </Button>
          <Button
            onClick={() => props.showModal('info', location)}
            disabled={!location.remoteId}
            colorScheme="blue"
            variant="outline"
          >
            {translate('info')}
          </Button>
        </HStack>
      </AccordionPanel>
    </AccordionItem>
  )
}

ListItem.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
    remoteId: PropTypes.string,
  }).isRequired,
  showModal: PropTypes.func.isRequired,
}

export default ListItem
