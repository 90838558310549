import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Image } from '@chakra-ui/react'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const QRCode = () => {
  const query = useQuery()
  return (
    <Box m="3rem auto auto" w="50%">
      <Image w="60%" m="auto" style={{ imageRendering: 'pixelated' }} src={query.get('url')} />
    </Box>
  )
}

export default QRCode
