import React, { useState } from 'react'
import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Center,
  VStack,
  PinInput,
  PinInputField,
  FormControl,
  FormLabel,
  FormHelperText,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { locations } from '../../../api'

const AccessCheck = ({ location }) => {
  const toast = useToast()
  const [translate] = useTranslation()
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)

  const doCheck = async (e) => {
    e.preventDefault()
    setLoading(true)
    const [err, res] = await locations.checkPMCAccess(location.remoteId, value)

    if (err) {
      toast({ title: err.title || err.message, status: 'error' })
    } else {
      toast({
        title: translate(res.hasAccess ? 'accessCheckGranted' : 'accessCheckDenied', { ssn: value }),
        status: 'info',
      })
    }
    setLoading(false)
  }

  return (
    <>
      <ModalHeader>{translate('accessCheck')}</ModalHeader>
      <ModalCloseButton />
      <form onSubmit={doCheck}>
        <ModalBody>
          <FormControl id="ssn">
            <FormLabel>SSN</FormLabel>
            <PinInput
              size="sm"
              name="ssn"
              id="ssn"
              onChange={(v) => {
                console.log(v)
                setValue(v)
              }}
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
            <FormHelperText>{translate('ssnHelpText')}</FormHelperText>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" colorScheme="blue" isLoading={loading} isDisabled={value.length < 12 || loading}>
            {translate('doCheck')}
          </Button>
        </ModalFooter>
      </form>
    </>
  )
}

AccessCheck.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    remoteId: PropTypes.string.isREquired,
  }).isRequired,
}

export default AccessCheck
