import axios from 'axios'
import { URL, PMC_URL, PMC_TOKEN } from '../config'

export const client = axios.create({
  baseURL: URL,
  headers: {
    'content-type': 'application/json',
  },
})

export const pmcClient = axios.create({
  baseURL: PMC_URL,
  headers: {
    'content-type': 'application/json',
    accept: 'application/json',
    Authorization: `Token token=${PMC_TOKEN}`,
  },
})

client.setAuth = function (token) {
  this.defaults.headers['Authorization'] = token
  console.warn('headers:', this.defaults.headers)
}

client.clearAuth = function () {
  delete this.defaults.headers['Authorization']
  console.warn('headers:', this.defaults.headers)
}
