export const snakeCase = (params) => {
  const result = {}
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key] === undefined) {
      return null
    }
    result[key.replace(/[A-Z]/g, (l) => `_${l.toLowerCase()}`)] = params[key]
  })
  return result
}

export const serialize = (params) => {
  return Object.keys(params)
    .map((key) => {
      if (params[key] === null || params[key] === undefined) {
        return null
      }
      return `${key}=${encodeURIComponent(params[key])}`
    })
    .filter((key) => key !== null)
    .join('&')
}
