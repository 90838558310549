import i18n from 'i18next'
import moment from 'moment'
import 'moment/locale/sv'
import en from './en'
import sv from './sv'

import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: { ...en } },
      sv: { translation: { ...sv } },
    },
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

moment.locale(i18n.language)

export default i18n
