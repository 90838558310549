import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Tag, Heading, Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useUser } from '../user'

const Header = ({ onSignOut, user }) => {
  const [translate] = useTranslation()

  return (
    <Flex align="center" mb="1rem">
      <Box flex="1" pt="1rem" pb="1rem">
        <Heading>Remote Access</Heading>
      </Box>
      <Box p="1rem">{user.username}</Box>
      <Box p="1rem">
        <Tag variant="solid" colorScheme="blue">
          {user.role}
        </Tag>
      </Box>
      <Box p="1rem">
        <Button onClick={onSignOut}>{translate('signOut')}</Button>
      </Box>
    </Flex>
  )
}

Header.propTypes = {
  onSignOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }),
}

export default Header
