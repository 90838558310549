import React from 'react'
import { Flex, Box, Center, Button } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import PropTypes from 'prop-types'

export const Pagination = ({ page, isAtEnd, onChange }) => {
  return (
    <Flex justifyContent="center" align="center">
      <Box>
        <Button onClick={() => onChange(--page)} disabled={page === 1}>
          <ChevronLeftIcon />
        </Button>
      </Box>
      <Box p="1rem">{page}</Box>
      <Box>
        <Button onClick={() => onChange(++page)} disabled={isAtEnd}>
          <ChevronRightIcon />
        </Button>
      </Box>
    </Flex>
  )
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  isAtEnd: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
