import React, { useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import { useStateIfMounted } from '../../hooks'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Flex,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'

const SignInForm = (props) => {
  const [showPassword, setShowPassword] = useStateIfMounted(false)

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validate={props.validate}
      onSubmit={async (values, { setSubmitting }) => {
        await props.signIn(values)
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, errors, touched }) => {
        return (
          <Form>
            <Field name="username">
              {({ field }) => (
                <FormControl isInvalid={errors.username && touched.username}>
                  <FormLabel htmlFor="username">{props.translate('username')}</FormLabel>
                  <Input variant="flushed" {...field} id="username" placeholder={props.translate('username')} />
                  <FormErrorMessage>{errors.username}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field }) => (
                <FormControl mt={4} isInvalid={errors.password && touched.password}>
                  <FormLabel htmlFor="password">{props.translate('password')}</FormLabel>
                  <InputGroup>
                    <Input variant="flushed" {...field} type={showPassword ? 'text' : 'password'} id="password" />
                    <InputRightElement w="4.5rem">
                      <Button
                        as="a"
                        h="1.75rem"
                        size="sm"
                        onClick={() => {
                          setShowPassword(!showPassword)
                        }}
                      >
                        {props.translate(showPassword ? 'hide' : 'show')}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex justify="flex-end" mt={8} mb={4}>
              <Button colorScheme="blue" isLoading={isSubmitting} type="submit">
                Sign in
              </Button>
            </Flex>
          </Form>
        )
      }}
    </Formik>
  )
}

SignInForm.propTypes = {
  validate: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

export default SignInForm
