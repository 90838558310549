export const initialState = null

export const reducer = (state = initialState, { type, payload }) => {
  console.log('action type:', type, 'payload:', payload)
  switch (type) {
    case 'set_user':
      return { ...state, ...payload }
    case 'remove_user':
      return null
    default:
      return state
  }
}
