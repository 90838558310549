import React, { useEffect } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useStateIfMounted } from '../../hooks'
import Form from './sign-in-form'
import { useTranslation } from 'react-i18next'
import { sessions } from '../../api'

const Auth = (props) => {
  const [open, setOpen] = useStateIfMounted(false)
  const toast = useToast()

  const [translate] = useTranslation()

  useEffect(() => {
    setOpen(true)
  }, [])

  const validate = (values) => {
    const errors = {}
    if (!values.username) {
      errors.username = `${translate('username')} ${translate('isRequired')}`
    }
    if (!values.password) {
      errors.password = `${translate('password')} ${translate('isRequired')}`
    }
    return errors
  }

  const signIn = async (values) => {
    const [err, data] = await sessions.signIn(values.username, values.password)

    if (err) {
      console.error('sign in session error:', err.message)
      toast({
        status: 'error',
        duration: 3000,
        title: translate('error.title'),
        description: translate('error.signInFailed'),
      })
    } else {
      props.onSignIn(data)
      onClose()
    }
  }

  return (
    <Modal
      isOpen={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remote Access - {translate('signIn')}</ModalHeader>
        <ModalBody>
          <Form validate={validate} signIn={signIn} translate={translate} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

Auth.propTypes = {
  onSignIn: PropTypes.func.isRequired,
}

export default Auth
