import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ModalHeader, ModalCloseButton, ModalBody, Button, List, ListItem, useToast, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { locations } from '../../../api'

// TODO: localizations missing
// TODO: Refactor

const Doors = ({ location }) => {
  const toast = useToast()
  const [translate] = useTranslation()
  const [doors, setDoors] = useState([])
  const [selected, setSelected] = useState()

  const [doorsActionState, setDoorsActionState] = useState([])

  const fetchData = async (locationId) => {
    const [err, data] = await locations.fetchDoors({ locationId })

    if (err) {
      toast({ title: err.title || err.message, status: 'error' })
    } else {
      // const test = data.find((d) => d.particleId === 'e00fce688ba06170491df35f')
      setDoors(data)
      if (data.length) {
        setSelected(data[0].id)
      }
    }
  }

  const open = async (door) => {
    setDoorsActionState((states) =>
      states.map((s) => {
        if (s.id === door.id) {
          s.loading = true
        }
        return s
      }),
    )
    const [err, res] = await locations.openDoor(door.id)

    if (err) {
      toast({ title: err.title || err.message, status: 'error' })
    } else if (res.outcome === 'Failure') {
      toast({ title: 'Porten kunde inte öppnas', status: 'error' })
    } else {
      toast({ title: translate('openSuccess'), status: 'success', isClosable: true })
    }
    setDoorsActionState((states) =>
      states.map((s) => {
        if (s.id === door.id) {
          s.loading = false
        }
        return s
      }),
    )
  }

  useEffect(() => {
    fetchData(location.id)
  }, [location.id])

  useEffect(() => {
    const actionState = doors.map((door) => {
      return { id: door.id, idleState: door.relayIdleState, loading: false }
    })
    setDoorsActionState(actionState)
  }, [doors])

  return (
    <>
      <ModalHeader>{translate('doors')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={10}>
        {doors.length === 0 && translate('noDoors')}
        <List spacing={5}>
          {doors.map((door) => {
            const state = doorsActionState.find(({ id }) => door.id === id)
            return (
              <ListItem
                onClick={() => setSelected(door.id)}
                alignContent="center"
                borderBottom="1px solid #3182ce"
                key={door.id}
                pb={3}
                d="flex"
                justifyContent="space-between"
                _last={{ borderBottom: '1px solid transparent' }}
              >
                <Text fontWeight="700" fontSize="lg">
                  {door.description}
                  <Text as="span" display="block" color="blue.300" cursor="pointer" fontWeight="700" fontSize="md">
                    <Link to={`/qr?url=${locations.getQRCodeURL(door.id)}`}>Skapa QR-kod</Link>
                  </Text>
                </Text>
                <Button
                  onClick={() => {
                    open(door)
                  }}
                  colorScheme="blue"
                  isLoading={state?.loading}
                  isDisabled={!selected || state?.loading}
                >
                  Öppna
                </Button>
              </ListItem>
            )
          })}
        </List>
      </ModalBody>
    </>
  )
}

Doors.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default Doors
