import React, { useEffect, useState } from 'react'
import {
  Flex,
  Box,
  Heading,
  InputGroup,
  Input,
  InputLeftElement,
  StackDivider,
  VStack,
  Accordion,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { locations as api } from '../../api'
import { Pagination } from '../pagination'
import { useTranslation } from 'react-i18next'
import ListItem from './list-item'
import Doors from './doors'
import Info from './info'
import AccessCheck from './access-check'

const Locations = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [selectedModal, setSelectedModal] = useState()
  const [searchValue, setSearchValue] = useState('')

  const [locations, setLocations] = useState([])
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  })

  const [translate] = useTranslation()

  let pmcData = []
  const fetchPmcData = async () => {
    let [err, data] = await api.fetchPMCLocations()
    pmcData = data
  }

  const fetchLocations = async () => {
    let [err, data] = await api.fetchLocations({ ...params })

    setLocations(data)
  }

  useEffect(() => {
    fetchPmcData().then(fetchLocations)
  }, [])

  useEffect(() => {
    if (searchValue !== '') {
      setParams({ page: 1, pageSize: 10, name: searchValue })
    } else {
      setParams({ page: params.page, pageSize: params.pageSize, name: undefined })
    }
  }, [searchValue])

  useEffect(() => {
    fetchLocations()
  }, [params])

  const showModal = (type, location) => {
    setSelectedModal({ type, location })
    onOpen()
  }

  return (
    <>
      <Flex justify="space-between" align="center" paddingRight="1rem">
        <Box>
          <Heading size="lg">{translate('locations')}</Heading>
        </Box>

        <Box>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon />
            </InputLeftElement>
            <Input
              variant="flushed"
              type="search"
              onChange={({ target: { value } }) => setSearchValue(value)}
              placeholder="Search"
            />
          </InputGroup>
        </Box>
      </Flex>

      <VStack
        mt="1rem"
        mb="1rem"
        divider={<StackDivider borderColor="gray" />}
        spacing={4}
        align="stretch"
        paddingRight="1rem"
      >
        <Accordion allowToggle>
          {locations.map((loc, idx) => (
            <ListItem key={idx} location={loc} showModal={showModal} />
          ))}
        </Accordion>
      </VStack>

      <Pagination
        onChange={(next) => setParams({ ...params, page: next })}
        page={params.page}
        isAtEnd={locations.length < 10}
      />
      <Modal
        size={selectedModal?.type === 'info' ? 'full' : 'xl'}
        isOpen={isOpen}
        onClose={() => {
          setSelectedModal(undefined)
          onClose()
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={selectedModal?.type === 'info' ? '100%' : '80%'}>
          {selectedModal?.type === 'access_check' && <AccessCheck location={selectedModal.location} />}
          {selectedModal?.type === 'doors' && <Doors location={selectedModal.location} />}
          {selectedModal?.type === 'info' && <Info location={selectedModal.location} />}
        </ModalContent>
      </Modal>
    </>
  )
}

export default Locations
